<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!--特码显示 -->

      <div class="cate cm">
        <span :class="zmtActive == '1' ? 'selected' : ''" @click="setTemaActive('1')">正特一</span>
        <span :class="zmtActive == '2' ? 'selected' : ''" @click="setTemaActive('2')">正特二</span>
        <span :class="zmtActive == '3' ? 'selected' : ''" @click="setTemaActive('3')">正特三</span>
        <span :class="zmtActive == '4' ? 'selected' : ''" @click="setTemaActive('4')">正特四</span>
        <span :class="zmtActive == '5' ? 'selected' : ''" @click="setTemaActive('5')">正特五</span>
        <span :class="zmtActive == '6' ? 'selected' : ''" @click="setTemaActive('6')">正特六</span>
      </div>
      <!-- 游戏 内容 -->
      <div class="player listmain type-lhc bjamlh game-bjamlh game-lhc-tm" style="left: 200px">
        <div class="data ctm" code="tm">
          <ul class="customer_ul" v-for="(item, index) in rowsData" :key="index">
            <li v-for="ele in item.arr" :balls="ele.index" :class="ele.selected ? 'selected' : ''" :key="ele.index" @click="setCurrentSelected(ele)">
              <template>
                <ol style="width: 35px" class="td_name">
                  <div :class="ele.color + 'ball ' + 'b' + ele.label"></div>
                </ol>
                <ol class="td_rate">
                  {{
                   ele.Odds
                  }}
                </ol>
                <ol class="td_cash">
                  <input @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" type="number" />
                  <div class="quick-check" v-if="ele.b">
                    <ul>
                      <li v-for="qs in quickSelectedData" :key="qs.num" @click.stop="setCurrentName(ele, qs.num)">
                        下注{{ qs.num }}元
                      </li>

                      <li @click="closeCheck(ele)">关闭</li>
                    </ul>
                  </div>
                </ol>
              </template>
            </li>
            <li>
              <ol class="td_empty" style="width:100%;height:24px;"></ol>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="lhc-tm-quick">
        <span>快捷投注</span>
        <textarea v-model="textarea" @keyup.enter="submitTextArea" placeholder="按号码=金额的格式，多个用空格分隔。如1=10 12=20" cols="30" rows="10"></textarea>
      </div> -->
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span class="selected">快选投注</span>
        </div>
        <div class="rc-tab-lhcquick">
          <ul>
            <li>
              <b @click="selected0">0头</b>
              <b @click="selected1">1头</b>
              <b @click="selected2">2头</b>
              <b @click="selected3">3头</b>
              <b @click="selected4">4头</b>
              <b @click="selected5(0)">0尾</b>
              <b @click="selected5(1)">1尾</b>
              <b @click="selected5(2)">2尾</b>
              <b @click="selected5(3)">3尾</b>
              <b @click="selected5(4)">4尾</b>
              <b @click="selected5(5)">5尾</b>
              <b @click="selected5(6)">6尾</b>
              <b @click="selected5(7)">7尾</b>
              <b @click="selected5(8)">8尾</b>
              <b @click="selected5(9)">9尾</b>
              <b @click="selectedsx(0)">鼠</b>
              <b @click="selectedsx(1)">牛</b>
              <b @click="selectedsx(2)">虎</b>
              <b @click="selectedsx(3)">兔</b>
              <b @click="selectedsx(4)">龙</b>
              <b @click="selectedsx(5)">蛇</b>
              <b @click="selectedsx(6)">马</b>
              <b @click="selectedsx(7)">羊</b>
              <b @click="selectedsx(8)">猴</b>
              <b @click="selectedsx(9)">鸡</b>
              <b @click="selectedsx(10)">狗</b>
              <b @click="selectedsx(11)">猪</b>
            </li>
            <li>
              <b class="red" @click="selhong('red')">红</b>
              <b class="red" @click="selhong1('red')">红单</b>
              <b class="red" @click="selhong2('red')">红双</b>
              <b class="red" @click="selhong3('red')">红大</b>
              <b class="red" @click="selhong4('red')">红小</b>
            </li>
            <li>
              <b class="blue" @click="selhong('blue')">蓝</b>
              <b class="blue" @click="selhong1('blue')">蓝单</b>
              <b class="blue" @click="selhong2('blue')">蓝双</b>
              <b class="blue" @click="selhong3('blue')">蓝大</b>
              <b class="blue" @click="selhong4('blue')">蓝小</b>
            </li>
            <li>
              <b class="green" @click="selhong('green')">绿</b>
              <b class="green" @click="selhong1('green')">绿单</b>
              <b class="green" @click="selhong2('green')">绿双</b>
              <b class="green" @click="selhong3('green')">绿大</b>
              <b class="green" @click="selhong4('green')">绿小</b>
            </li>
            <li>
              <b @click="selda('单')">大单</b>
              <b @click="selda('双')">大双</b>
              <b @click="selxiao('单')">小单</b>
              <b @click="selxiao('双')">小双</b>
            </li>
            <li>
              <b @click="selhe('单')">合单</b>
              <b @click="selhe('双')">合双</b>
              <b @click="selhe1('大')">合大</b>
              <b @click="selhe1('小')">合小</b>
            </li>
            <li>
              <b @click="selwei('单')">尾单</b>
              <b @click="selwei('双')">尾双</b>
              <b @click="selwei1('大')">尾大</b>
              <b @click="selwei1('小')">尾小</b>
            </li>
            <li>
              <b @click="selAll">全选</b>
              <b @click="selFanAll">反选</b>
              <b @click="selCancel">取消</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <submitTK
     
    /> -->
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import submitTK from "../submitTK";
import mixins from "../mixins/mainMixins";
import { mapState } from "vuex";
export default {
  name: "lhczmt",
  props: [""],
  data() {
    return {
      groupnames:'zmt',
      shu: ["03", "15", "27", "39"],
      niu: ["02", "14", "26", "38"],
      hu: ["01", "13", "25", "37", "49"],
      tu: ["12", "24", "36", "48"],
      long: ["11", "23", "35", "47"],
      she: ["10", "22", "34", "46"],
      ma: ["09", "21", "33", "45"],
      yang: ["08", "20", "32", "44"],
      hou: ["07", "19", "31", "43"],
      ji: ["06", "18", "30", "42"],
      gou: ["05", "17", "29", "41"],
      zhu: ["04", "16", "28", "40"],

      rowsData: [],
      zhengte1: {
        title: "正特一",
        arr: [],
      },
      zhengte2: {
        title: "正特二",
        arr: [],
      },
      zhengte3: {
        title: "正特三",
        arr: [],
      },
      zhengte4: {
        title: "正特四",
        arr: [],
      },
      zhengte5: {
        title: "正特五",
        arr: [],
      },
      zhengte6: {
        title: "正特六",
        arr: [],
      },
      textarea: "",
      colorArr: [
        "red",
        "red",
        "blue",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "blue",
        "green",
        "red",
        "red",
        "blue",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "blue",
        "green",
        "green",
        "red",
        "blue",
        "blue",
        "green",
        "green",
        "red",
        "red",
        "blue",
        "blue",
        "green",
      ],
      labelArr: [],
      temaActive: "1",
      //   下注确认弹框  bool
      xiazhuvisible: false,
      xiazhuList: [],
      //   预设金额
    };
  },
  components: {
    yushe,
    topTimer,
    submitTK,
    yanzheng,
  },
  mixins: [mixins],
  mounted() {},
  watch: {
    pan(val) {
      this.temaActive = "1";
    },
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        this.labelArr = [];
        for (let index = 1; index < 50; index++) {
          this.labelArr.push(this.checkNum(index));
        }
        // 正特一
        let arr1 = this.filterData(resAArr, 122, 170, this.labelArr);

        arr1.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte1.arr = [...arr1];
        // 正特二
        let arr2 = this.filterData(resAArr, 171, 219, this.labelArr);
        arr2.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte2.arr = [...arr2];
        // 正特三
        let arr3 = this.filterData(resAArr, 220, 268, this.labelArr);
        arr3.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte3.arr = [...arr3];
        // 正特四
        let arr4 = this.filterData(resAArr, 269, 317, this.labelArr);
        arr4.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte4.arr = [...arr4];
        // 正特五
        let arr5 = this.filterData(resAArr, 318, 366, this.labelArr);
        arr5.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte5.arr = [...arr5];
        // 正特六
        let arr6 = this.filterData(resAArr, 367, 415, this.labelArr);
        arr6.forEach((ele, index) => {
          ele.color = this.colorArr[index];
        });
        this.zhengte6.arr = [...arr6];
        // console.log(this.temaA, "TEMAa");
        // this.rowsData = [JSON.parse(JSON.stringify(this.zhengte1))];
        this.getZMTActive();
        this.$forceUpdate();
      },
    },
  },

  created() {},

  methods: {
    // 监听enter
    submitTextArea() {
      if (this.textarea) {
        console.log(this.textarea);
        let text = this.textarea.trim();
        let arr = text.split(" ");

        let xiazhu = [];
        arr.forEach((ele) => {
          let label = ele.split("=")[0];
          let money = ele.split("=")[1];
          if (label >= 1 && label <= 49) {
            let obj = this.rowsData[0].arr.find(
              (item) => item.label == this.checkNum(label)
            );
            if (obj) {
              let obj1 = {
                label: obj.label,
                Odds: obj.Odds,
                title: this.rowsData[0].title,
                id: obj.ResultID,
                money: Number(money),
              };
              xiazhu.push(obj1);
            }
          }
        });

        if (xiazhu.length > 0) {
          this.$store.commit("setXZList", xiazhu);
          this.$store.commit("showOrHideXZTK", true);
        } else {
          this.$store.commit("setXZList", []);
        }
      }
    },
    checkNum(num) {
      if (num < 10) {
        num = "0" + num;
      }
      return num;
    },
    //   设置特码or特码B
    setTemaActive(str) {
      // this.temaActive = str;
      this.$store.commit("SETZMTACTIVE", str);
      this.rowsData = [JSON.parse(JSON.stringify(this["zhengte" + str]))];

      if (this.ISOPEN) {
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = item.recordOdds;
        });
      }else{
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = '--';
        });
      }
    },
    getZMTActive() {
      this.rowsData = [
        JSON.parse(JSON.stringify(this["zhengte" + this.zmtActive])),
      ];

      if (this.ISOPEN) {
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = item.recordOdds;
        });
      }else{
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = '--';
        });
      }
    },
    // 取消
    selCancel() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = false;
          ele.money = "";
        });
      });
      this.$forceUpdate();
    },
    // 选择生肖
    selectedsx(num) {
      let currentArr = this.ballArr[num];
      // console.log(this.rowsData[0], currentArr);
      this.rowsData[0].arr.forEach((item) => {
        let hadLabel = currentArr.find((la) => la == item.label);
        if (hadLabel) {
          item.selected = !item.selected;
          item.money = item.selected ? this.amount : "";
        }
      });
      this.$forceUpdate();
    },
    // 反选
    selFanAll() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = !ele.selected;
          ele.money = ele.selected ? this.amount : "";
        });
      });
      this.$forceUpdate();
    },
    // ------------------------------------------------------
    selwei1(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          let numstr = ele.label + "";
          let heshu = Number(numstr[1]);
          if (str == "大") {
            if (heshu >= 5) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "小") {
            if (heshu < 5) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // 尾单 尾双
    selwei(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          let numstr = ele.label + "";
          let heshu = Number(numstr[1]);
          if (str == "单") {
            if (heshu % 2 != 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "双") {
            if (heshu % 2 == 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // 合大 合小
    selhe1(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          let numstr = ele.label + "";
          let heshu = Number(numstr[0]) + Number(numstr[1]);
          if (str == "大") {
            if (heshu >= 7) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "小") {
            if (heshu < 7) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // 合单  合双
    selhe(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          let numstr = ele.label + "";
          let heshu = Number(numstr[0]) + Number(numstr[1]);
          if (str == "单") {
            if (heshu % 2 != 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "双") {
            if (heshu % 2 == 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // 小单 小双
    selxiao(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (str == "单") {
            if (ele.label < 25 && ele.label % 2 != 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "双") {
            if (ele.label < 25 && ele.label % 2 == 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // 大单   大双
    selda(str) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (str == "单") {
            if (ele.label >= 25 && ele.label % 2 != 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          } else if (str == "双") {
            if (ele.label >= 25 && ele.label % 2 == 0) {
              ele.selected = !ele.selected;
              ele.money = ele.selected ? this.amount : "";
            }
          }
        });
      });
    },
    // -----------------------------------------------------
    // 全选
    selAll() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = true;
          ele.money = this.amount;
        });
      });
      this.$forceUpdate();
    },
    //   红小 蓝小 绿小
    selhong4(color) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.color == color && ele.label < 25) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   红大  蓝大 绿大
    selhong3(color) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.color == color && ele.label >= 25) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   红双  蓝双 绿双
    selhong2(color) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.color == color && ele.label % 2 == 0) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   红单 蓝单 绿单
    selhong1(color) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.color == color && ele.label % 2 != 0) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   红  蓝  绿
    selhong(color) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.color == color) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    // 选择  尾数 一样的
    selected5(num) {
      this.rowsData[0].arr.forEach((ele) => {
        let label = String(ele.label);
        console.log(label, label.substr(label.length - 1));
        if (label.substr(label.length - 1) == num) {
          ele.selected = !ele.selected;
          ele.money = ele.selected ? this.amount : "";
        }
      });
      this.$forceUpdate();
    },
    //   4头
    selected4() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.label < 50 && ele.label > 39) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   3头
    selected3() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.label < 40 && ele.label > 29) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   2头
    selected2() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.label < 30 && ele.label > 19) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   1头
    selected1() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.label < 20 && ele.label > 9) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
    //   0头
    selected0() {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          if (ele.label < 10) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.customer_ul {
  height: 341px !important;
}
.listmain > .data > ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
}
.listmain div.ctm {
  height: 341px;
}
.listmain div.ctm ul li {
  width: 20%;
  height: 34px;
}
</style>
